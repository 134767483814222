import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Global } from '@emotion/core';
import LayoutRoot from './root/LayoutRoot';
import Header from '../components/Header';
import LayoutMain from './main/LayoutMain';
import { globalStyleResetCss } from './default.styles';

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string;
      description: string;
      keywords: string;
    };
  };
}

const DefaultLayout: React.FC = ({ children }) => (
  <>
    <Global styles={globalStyleResetCss} />
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
              keywords
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <LayoutRoot>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: data.site.siteMetadata.keywords }
            ]}
          />
          <Header title={data.site.siteMetadata.title} />
          <LayoutMain>{children}</LayoutMain>
        </LayoutRoot>
      )}
    />
  </>
);

export default DefaultLayout;
