export const colors = {
  'apple-blossom': '#ac4142',
  alto: '#d0d0d0',
  bouquet: '#aa759f',
  'chelsea-cucumber': '#90a959',
  'cod-grey': '#151515',
  conifer: '#b5e853',
  'dove-grey': '#666',
  white: '#eaeaea',
  grey: '#888',
  'gulf-stream': '#75b5aa',
  'hippie-blue': '#6a9fb5',
  'potters-clay': '#8f5536',
  rajah: '#f4bf75',
  'raw-sienna': '#d28445',
  'silver-chalice': '#aaa'
};

export const fonts = {
  monospace: 'Monaco, "Bitstream Vera Sans Mono", "Lucida Console", Terminal, monospace'
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200
};

export const dimensions = {
  containerPadding: 1.5
};

export const heights = {
  header: 60
};
