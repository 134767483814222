import { css } from '@emotion/core';
import emotionReset from 'emotion-reset';
import { colors, fonts } from '../styles/variables';

export const globalStyleResetCss = css`
  ${emotionReset}
  *, *::after, *::before {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  html,
  body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
    color: ${colors.white};
    font-size: 16px;
    line-height: 1.5;
    font-family: ${fonts.monospace};
  }
`;
